import { toggleHamburger } from "./toggleHamburger";
toggleHamburger("js-hamburger", "js-spmenu", 1024);

document.addEventListener("DOMContentLoaded", function () {
  // アニメーション
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("_active");
        }
      });
    },
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0,
    }
  );

  const elements = document.querySelectorAll(".js-animation");
  elements.forEach((element) => {
    observer.observe(element);

    if (
      element.getBoundingClientRect().top <= window.innerHeight &&
      element.getBoundingClientRect().bottom >= 0
    ) {
      element.classList.add("_active");
    }
  });

  // ヘッダー制御

  var topMvElement = document.querySelector(".js-top-mv");
  var headerElement = document.querySelector(".js-header");

  if (topMvElement && headerElement) {
    var topMvRect = topMvElement.getBoundingClientRect();
    window.addEventListener("scroll", function () {
      if (topMvRect.bottom <= window.scrollY) {
        headerElement.classList.add("_active");
      } else {
        headerElement.classList.remove("_active");
      }
    });
  }

  // スムーススクロール
  var scrollLinks = document.querySelectorAll('a[href^="#"]');
  scrollLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();

      var targetId = this.getAttribute("href").substring(1);
      var targetElement = document.getElementById(targetId);
      if (targetElement) {
        var offsetTop =
          targetElement.getBoundingClientRect().top + window.pageYOffset - 85;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    });
  });
});

// Infinite Scroll
const infScrollSetup = function () {
  const nextPostsLink = document.querySelector(".nextpostslink");
  if (!nextPostsLink) return;
  const infScroll = new InfiniteScroll("#js-infinite-list", {
    append: ".c-news-card",
    path: ".nextpostslink",
    hideNav: ".wp-pagenavi",
    button: "#js-more-btn",
    scrollThreshold: false,
    status: ".scroller-status",
    // history: 'push',
  });
};
infScrollSetup();
